import React, { Component } from "react";
import Select from "react-dropdown-select";

const org = ["#DF740C", "#FFE64D", "#E6FFFF", "#6FC3DF"];
const blue = ["#0F4C81", "#658DC6", "#B5C7D3", "#A58D7F", "#F3D5AD"];
const snorkel = ["#F26B5B", "#0F4C81", "#F1EEE6", "#C0D725"];
const coral = ["#9A9739", "#726A4E", "#FF6F61", "#A75D67"];
const sunset = ["#FC8F9B", "#E55982", "#E881A6", "#9D446E", "#FF6F61"];
const violet = ["#634c95", "#ecebec", "#938f9c"];
const sea = ["#1A5190", "#3686A0", "#5F4B8B", "#B7C0D7"];
const halloween = ["#C03C09", "#EB6123", "#F5CD08", "#67A032"];

export default class ColorSelect extends Component {
  state = {};

  onChange = (values) => {
    this.props.changeColor(values)
  }

  render() {
    const options = [
      {label: "Original", value: org},
      {label: "Halloween", value: halloween},
      {label: "2020 Blue", value: blue},
      {label: "Snorkel", value: snorkel},
      {label: "2019 Coral", value: coral},
      {label: "Sunset", value: sunset},
      {label: "2018 Ultra Violet", value: violet},
      {label: "Sea", value: sea},
    ]
    return (
      <Select
        color="#fff"
        values={[options[0]]}
        dropdownGap={0}
        options={options}
        onChange={(values) => this.onChange(values[0]["value"])}
      />
    );
  }
}
