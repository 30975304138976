import React, { Component } from 'react';
import ReactWordcloud from "react-wordcloud";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import './App.css';
import Form from './Form';
import ColorSelect from './ColorSelect';

const LoadingIndicator = (message) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress &&
    <div
      style= {{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Loader type="TailSpin" color="#6FC3DF" secondaryColor= "#FFE64D" height = "100" width = "100" />
       <p>{message.message}</p>
    </div >
  );
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

class App extends Component {
  constructor(props) {
    super(props);
    let appName = getQueryVariable("app");
    let rating = getQueryVariable("rating")

    this.state = {
      words: [],
      error: "",
      colorPalette: ["#DF740C", "#FFE64D", "#E6FFFF", "#6FC3DF"],
      appName: appName,
      rating: rating
    };
  }

  changeColor = (arr) => {
    this.setState({colorPalette: arr})
  }

  handleSubmit = (url, appName, params) => {
    console.log(url)
    var arr = [];

    this.setState({ words: [] })
    let queryString = `/?app=${appName}`
    let queryParams = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    if (queryParams) {
      queryString = `${queryString}&${queryParams}`
    }
    window.history.pushState({app: appName}, `Reviews - ${appName}`, queryString);

    trackPromise(
      fetch(url)
        .then(async (response) => {
          if (response.status === 404) {
            await fetch(
              process.env.REACT_APP_API_URL + "/scrape/shopify/" + appName
            )
              .then(this.handleErrors)
              .then(() => {
                throw Error("Scraping started...");
              })
              .then(this.retryRequest(url, 10, 100))
              .then((response) => response);
          }
          return response;
        })
        .then(this.handleErrors)
        .then((response) => response.json())
        .then((data) => {
          let value = data["data"]["attributes"]["word_count_json"];
          if (value !== "{}") {
            var words_count = JSON.parse(value);
            for (var key in words_count) {
              var obj = { text: key, value: words_count[key]["count"] };
              arr.push(obj);
            }
          } else {
            throw Error("No reviews found");
          }
        })
        .then(() => {
          this.setState({ words: arr, error: "" });
        })
        .catch((error) => {
          this.setState({ error: error.message });
        })
      );
  };

  handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    this.setState({ [name]: val });
  };

  handleErrors = async (response) => {
    if (!response.ok) {
      throw Error(
        await response.json().then((data) => {
          return data["errors"][0]["detail"];
        })
      );
    }
    return response;
  };

  retryRequest = async (url, retriesRemaining, timeout) => {
    await fetch(url)
      .then(this.handleErrors)
      .then((response) => {
        return response;
      })
      .catch(async (err) => {
        timeout *= 2;
        console.log(err);
        await this.delay(timeout);
        return this.retryRequest(url, retriesRemaining--, timeout);
      });
  };

  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  render() {
    const { words, error, appName, rating } = this.state;

    return (
      <div className="App">
        <header className="App-header">
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
            }}
          >
            <ColorSelect changeColor={this.changeColor}></ColorSelect>
          </div>
          <Form handleSubmit={this.handleSubmit} appName={appName} rating={rating}></Form>
          <div className="screen">
            <LoadingIndicator message={this.state.message} />
            {error ? (
              <h3>{error}</h3>
            ) : (
              <ReactWordcloud
                maxWords={window.innerWidth > 768 ? 300 : 75}
                words={words}
                options={{
                  colors: this.state.colorPalette,
                  deterministic: false,
                  enableTooltip: true,
                  fontFamily: "impact",
                  fontSizes: [10, 100],
                  fontStyle: "normal",
                  fontWeight: "normal",
                  padding: 1,
                  randomSeed: null,
                  rotations: 1,
                  rotationAngles: [0, 0],
                  scale: "sqrt",
                  spiral: "rectangular",
                  svgAttributes: {
                    role: "list",
                  },
                  textAttributes: {
                    "aria-label": (word) =>
                      `Word: "${word.text}", Count: "${word.value}"`,
                    role: "img",
                  },
                  tooltipOptions: {
                    allowHTML: true,
                    arrow: false,
                    placement: "bottom",
                  },
                  transitionDuration: 1000,
                }}
              />
            )}
          </div>
        </header>
      </div>
    );
  }

  componentDidMount() {
    let appName = this.state.appName;
    let rating = this.state.rating
    let params = {}
    if (appName) {
      let url = process.env.REACT_APP_API_URL + "/shopify/" + appName;
      if (rating) {
        params["rating"] = rating;
        url = url + `?rating=${this.state.rating}`;
      }
      this.handleSubmit(url, appName, params);
    }
  }
}

export default App;
