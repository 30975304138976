import React, { Component } from 'react';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Form extends Component {
  state = {
    name: this.props.appName || null,
    rating: this.props.rating
  };

  handleChange = (e) => {
    const { name, type, value } = e.target;
    const val = type === "number" ? parseFloat(value) : value;
    this.setState({ [name]: val });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var appName = this.state.name;

    let url = process.env.REACT_APP_API_URL + "/shopify/" + appName;
    let params = {}
    if (this.state.rating) {
      params["rating"] = this.state.rating
      url = url + `?rating=${this.state.rating}`;
    }

    this.props.handleSubmit(url, appName, params);
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Shopify App Name:
          <input
            type="text"
            name="name"
            value={this.state.name}
            onChange={this.handleChange}
            required
          />
        </label>
        <div>
          <input
            type="radio"
            id="star-1"
            name="rating"
            value="1"
            onChange={this.handleChange}
            style={{ opacity: 0 }}
          ></input>
          <label htmlFor="star-1" title="1 stars">
            <FontAwesomeIcon
              icon={faStar}
              color={this.state.rating >= 1 ? "yellow" : "white"}
            />
          </label>
          <input
            type="radio"
            id="star-2"
            name="rating"
            value="2"
            onChange={this.handleChange}
            style={{ opacity: 0 }}
          ></input>
          <label htmlFor="star-2" title="2 stars">
            <FontAwesomeIcon
              icon={faStar}
              color={this.state.rating >= 2 ? "yellow" : "white"}
            />
          </label>
          <input
            type="radio"
            id="star-3"
            name="rating"
            value="3"
            onChange={this.handleChange}
            style={{ opacity: 0 }}
          ></input>
          <label htmlFor="star-3" title="3 stars">
            <FontAwesomeIcon
              icon={faStar}
              color={this.state.rating >= 3 ? "yellow" : "white"}
            />
          </label>
          <input
            type="radio"
            id="star-4"
            name="rating"
            value="4"
            onChange={this.handleChange}
            style={{ opacity: 0 }}
          ></input>
          <label htmlFor="star-4" title="4 stars">
            <FontAwesomeIcon
              icon={faStar}
              color={this.state.rating >= 4 ? "yellow" : "white"}
            />
          </label>
          <input
            type="radio"
            id="star-5"
            name="rating"
            value="5"
            onChange={this.handleChange}
            style={{ opacity: 0 }}
          ></input>
          <label htmlFor="star-5" title="5 star">
            <FontAwesomeIcon
              icon={faStar}
              color={this.state.rating >= 5 ? "yellow" : "white"}
            />
          </label>
          <input
            type="radio"
            id="star-all"
            name="rating"
            value=""
            onChange={this.handleChange}
            style={{ opacity: 0 }}
          ></input>
          <label
            htmlFor="star-all"
            title="all"
            style={{ color: this.state.rating ? "white" : "yellow" }}
          >
            All
          </label>
        </div>
        <input type="submit" value="Submit" />
      </form>
    );
  }
}